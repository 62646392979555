import {ModalsNotification} from "#components";

export function showError(message) {
    const toast = useToast()
    toast.add({
        title: message,
        icon: 'i-heroicons-exclamation-circle',
        ui: {
            icon: {
                color: 'text-pink dark:text-pink',
            },
        }
    })
}

export function showSuccess(message, timeout = 2000) {
    const toast = useToast()
    toast.add({ title: message, timeout: timeout })
}

export function showModalMessage(message) {
    const modal = useModal()
    modal.open(ModalsNotification, {
        message: message,
        onSuccess () {
            modal.close()
        },
        onError() {
            modal.close()
        }
    })
}



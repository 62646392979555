import {useCities} from "~/composables/cities";
import {showError} from "~/utils/notifications";
import {useOrders} from "~/composables/order";

export const fetchConfig = {
    headers: {
        'Access-Control-Allow-Origin': 'Same-Origin',
        'crossOriginResourcePolicy': 'same-origin',
        'crossOriginOpenerPolicy': 'same-origin',
        'crossOriginEmbedderPolicy': 'require-corp'
    },
};

function useGetFetchOptions(options = {}) {
    const config = useRuntimeConfig()

    options.baseURL = config.public.apiUrl + 'client/api/v1/';
    options.headers =  {
        ...options.headers,
        ...fetchConfig.headers
    };
    options.initialCache = options.initialCache ?? false;
    options.lazy = options.lazy ?? false;

    const token = useCookie('auth.token')
    if (token.value) {
        options.headers.Authorization = `Bearer ${token.value}`;
    }

    if (options.body) {
        const rawBody = toRaw(options.body);
        options.body = typeof rawBody === "object" ? { ...rawBody } : rawBody;
        
        options.headers['content-type'] = 'application/json'
    }

    const { cityId } = useCities()
    const uuidAnonim = useCookie('uuidAnonim')
    if (!uuidAnonim.value) {
        uuidAnonim.value = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
    }

    options.query = {
        ...options.query,
        token_anonim_user: uuidAnonim.value,
        city_id: cityId.value
    }

    return options;
}

export async function useHttp(key, url, options = {}) {
    const toast = useToast()
    const { refresh } = useAuth()
    const { fetchOrders } = useOrders()
    options = useGetFetchOptions(options);
    options.key = key;

    if (options.$) {
        const data = ref(null);
        const error = ref(null);
        return await $fetch(url, options).then((res) => {
            data.value = res.data;
            return {
                data,
                error,
            };
        }).catch((err) => {
            const msg = err?.data?.data;
            if (process.client && msg && typeof msg === 'string') {
                toast.add({ title: msg })
                // const { message } = createDiscreteApi(["message"]);
                // message.error(msg || "");
            }
            error.value = msg;
            return {
                data,
                error,
            };
        });
    }

    let res = await useFetch(url, {
        ...options,
        // transform: (res) => {
        //     console.log(res)
        //     return res.data;
        // },
    });
    
    if (process.client) {
        if (res.status.value === 'success' && res.data.value) {
            const msg = res.data.value?.detail;
            if (!options.lazy && msg && typeof msg === 'string') {
                // toast.add({ title: msg })
            }
        } else if (res.status.value === 'error' && res.error.value?.data && res.error.value?.statusCode !== 401) {
            const msg = res.error.value?.data?.detail;
            if (!options.lazy && msg && typeof msg === 'string') {
                showError(msg)
            }
        } else if (res.error.value?.statusCode === 401) {
            refresh()
                .then(() => {
                    fetchOrders()
                })
        }
    }

    return res;
}

export function useHttpGet(url, options = {}) {
    options.method = "GET";
    return useHttp(url, url, options);
}

export function useHttpPost(url, options = {}) {
    options.method = "POST";
    return useHttp(url, url, options);
}

export function useHttpDelete(url, options = {}) {
    options.method = "DELETE";
    return useHttp(url, url, options);
}

export function useHttpPut(url, options = {}) {
    options.method = "PUT";
    return useHttp(url, url, options);
}
